<template>
  <div class="component-simulator-electricity-advanced">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title') }}</h2>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_advanced_simulator')"></div>
            </div>

            <b-tabs class="white-tabs filter-tabs-wrapper" v-model="tabIndex">
              <b-tab :title="$t(translationPath + 'tab_values')" class="pt-4">

                <h3>{{ $t(translationPath + 'title_values') }}</h3>
                <div class="col-lg-8 pl-0">
                  <div v-html="$t(translationPath + 'tab_values_description', {currentYear: currentYear, prevYear: currentYear-1, nextYear: currentYear+1 })"></div>
                </div>

                <template v-if="isLoaded">
                  <b-table-simple hover striped class="mt-3">
                    <b-thead>
                      <b-tr>
                        <b-th></b-th>
                        <b-th colspan="2">{{ $t(translationPath + 'previous_period') }}</b-th>
                        <b-th></b-th>
                        <b-th colspan="2">{{ $t(translationPath + 'next_period') }}</b-th>
                        <b-th colspan="2">{{ $t(translationPath + 'result') }}</b-th>
                      </b-tr>
                      <b-tr>
                        <b-th></b-th>
                        <b-th>{{ $t(translationPath + 'prev_kwh_used') }}</b-th>
                        <b-th>{{ $t(translationPath + 'prev_kwh_price', {currency: currentCOA.currency_iso}) }}</b-th>
                        <b-th></b-th>
                        <b-th>{{ $t(translationPath + 'next_kwh_prognosis') }}</b-th>
                        <b-th>{{ $t(translationPath + 'next_kwh_future_price') }}</b-th>
                        <b-th class="text-right">{{ $t(translationPath + 'next_period_cost', {currency: currentCOA.currency_iso}) }}</b-th>
                        <b-th class="text-right">{{ $t(translationPath + 'next_period_diff', {currency: currentCOA.currency_iso}) }}</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="month in 12" :key="month">
                          <b-td>
                            {{$t('common.months.month'+month+'_short')}}
                            <template>{{ month > currentMonth ? currentYear-1 : currentYear }}:</template>
                          </b-td>
                          <b-td><b-form-input type="number" v-model="prev12monthskWhUsed[month-1]" :class="currentMonth >= month ? 'bgColor01' : 'bgColor02'">{{month}}</b-form-input></b-td>
                          <b-td><b-form-input type="number" v-model="prev12monthsCost[month-1]" :class="currentMonth >= month ? 'bgColor01' : 'bgColor02'"></b-form-input></b-td>
                          <b-td>
                            {{$t('common.months.month'+month+'_short')}}
                            <template>{{ currentMonth >= month ? currentYear+1 : currentYear }}:</template>
                          </b-td>
                          <b-td><b-form-input type="number" v-model="next12monthskWhUsed[month-1]" :class="currentMonth >= month ? 'bgColor03' : 'bgColor04'"></b-form-input></b-td>
                          <b-td><b-form-input type="number" v-model="next12monthskWhPrice[month-1]" :class="currentMonth >= month ? 'bgColor03' : 'bgColor04'" @change="calculateBasedOnNewNextPrice"></b-form-input></b-td>
                          <b-td class="text-right">{{formatAmount(totals[month-1])}}</b-td>
                          <b-td class="text-right">{{formatAmount(totals_diff[month-1])}}</b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr>
                        <b-td><b>{{$t('common.total')}}</b></b-td>
                        <b-td><b>{{formatAmount(total_prev_kwh_used,false)}} kWh</b></b-td>
                        <b-td><b>{{formatAmount(total_prev_price)}}</b></b-td>
                        <b-td></b-td>
                        <b-td><b>{{formatAmount(total_next_kwh_used,false)}} kWh</b></b-td>
                        <b-td><b>{{$t('common.average')}}: {{formatAmount(total_next_price_average,false)}} öre/kWh</b></b-td>
                        <b-td class="text-right"><b>{{formatAmount(total_next_sum)}}</b></b-td>
                        <b-td class="text-right"><b>{{formatAmount(total_next_diff)}}</b></b-td>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                  <b-row no-gutters>
                    <b-col class="col-lg-6 text-left">
                      <ul class="legend-ul">
                        <li><span class="color01">&#9632;</span> {{$t(translationPath + 'historic_values', {year: currentYear} )}}</li>
                        <li><span class="color02">&#9632;</span> {{$t(translationPath + 'historic_values', {year: currentYear-1} )}}</li>
                      </ul>
                      <ul class="legend-ul">
                        <li><span class="color03">&#9632;</span> {{$t(translationPath + 'estimated_values', {year: currentYear + 1} )}}</li>
                        <li><span class="color04">&#9632;</span> {{$t(translationPath + 'estimated_values', {year: currentYear} )}}</li>
                      </ul>
                    </b-col>
                    <b-col class="col-lg-6 text-right">
                      <b-button variant="outline-primary" class="mr-2" @click="loadSavedData(true)">{{ $t(translationPath + 'reload') }}</b-button>
                      <b-button variant="primary" @click="onSaveClick">{{ $t(translationPath + 'save') }}</b-button>
                    </b-col>
                  </b-row>
                </template>
                <template v-else><loader /></template>
              </b-tab>

              <!-- SIMULATE TAB -->
              <b-tab :title="$t(translationPath + 'tab_simulate')">

                <b-row no-gutters class="pt-3">
                  <b-col class="col-lg-8">
                    <h3>{{ $t(translationPath + 'experiment_title') }}</h3>

                    <!-- Edit price percentage -->
                    <b-form-group
                      label-class="FormLabels"
                      label-for="kwh_price_modification_percent"
                      label-cols="12"
                      label-align="left"
                      :label="$t(translationPath + 'kwh_price_modification_percent')"
                    >
                      <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                        <custom-number-input
                          id="kwh_price"
                          v-model.number="kwh_price_modification_percent"
                          :precision="0"
                          :min="-100"
                          :max="100"
                          :suffix="' %'"
                        />
                      </b-col>
                      <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                        <b-form-input
                          id="kwh_price_modification_percent_slider"
                          class="slider ml-2"
                          type="range"
                          :min="-100"
                          :max="100"
                          :step="5"
                          v-model.number="kwh_price_modification_percent"
                        ></b-form-input>
                      </b-col>
                    </b-form-group>

                    <!-- Edit price by adding constant-->
                    <b-form-group
                      label-class="FormLabels"
                      label-for="kwh_price_modification_price"
                      label-cols="12"
                      label-align="left"
                      :label="$t(translationPath + 'kwh_price_modification_price')"
                    >
                      <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                        <custom-number-input
                          id="kwh_price_modification_price"
                          v-model.number="kwh_price_modification_price"
                          :precision="0"
                          :min="-100"
                          :max="100"
                          :suffix="' öre/kWh'"
                        />
                      </b-col>
                      <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                        <b-form-input
                          id="kwh_price_modification_price_slider"
                          class="slider ml-2"
                          type="range"
                          :min="-100"
                          :max="100"
                          :step="5"
                          v-model.number="kwh_price_modification_price"
                        ></b-form-input>
                      </b-col>
                    </b-form-group>

                    <!-- Edit kwh used by percentage -->
                    <b-form-group
                      label-class="FormLabels"
                      label-for="kwh_used_modification_percentage"
                      label-cols="12"
                      label-align="left"
                      :label="$t(translationPath + 'kwh_used_modification_percentage')"
                    >
                      <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                        <custom-number-input
                          id="kwh_used_modification_percentage"
                          v-model.number="kwh_used_modification_percentage"
                          :precision="0"
                          :min="-100"
                          :max="100"
                          :suffix="' %'"
                        />
                      </b-col>
                      <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                        <b-form-input
                          id="kwh_used_modification_percentage_slider"
                          class="slider ml-2"
                          type="range"
                          :min="-100"
                          :max="100"
                          :step="5"
                          v-model.number="kwh_used_modification_percentage"
                        ></b-form-input>
                      </b-col>
                    </b-form-group>

                  </b-col>
                </b-row>

              </b-tab>
            </b-tabs>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Loader from '@/components/common/Loader'
import CustomNumberInput from '@/components/common/CustomNumberInput'
import formatAmount from '@/assets/filters/formatAmount'

export default {
  name: 'ElectricitySimulatorAdvanced',
  components: { Loader, CustomNumberInput },
  filters: { formatAmount },
  data () {
    return {
      translationPath: 'reports.simulators.electricity.advanced.',
      totals: new Array(12).fill(0),
      totals_diff: new Array(12).fill(0),
      prev12monthskWhUsed: new Array(12).fill(0),
      prev12monthsCost: new Array(12).fill(0),
      next12monthskWhUsed: new Array(12).fill(0),
      next12monthskWhPrice: new Array(12).fill(0),
      total_prev_kwh_used: 0,
      total_prev_price: 0,
      total_next_kwh_used: 0,
      total_next_price_average: 0,
      total_next_sum: 0,
      total_next_diff: 0,
      isBtnSaveEnabled: true,
      isLoaded: false,
      kwh_price_modification_percent: 0,
      kwh_price_modification_price: 0,
      kwh_used_modification_percentage: 0,
      next12monthskWhPriceCacheForPercentChange: new Array(12).fill(0),
      next12monthskWhUsedCacheForPercentChange: new Array(12).fill(0),
      tabIndex: 0,
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    emitAdvancedData () {
      this.$emit('advanced-simulator-data', { prev_prices: this.prev12monthsCost, new_prices: this.totals })
    },
    formatAmount (amount, showCurrencyLabel = true) {
      if (this.currentCOA.locale) {
        amount = formatAmount(
          amount,
          this.currentCOA.locale,
          this.currentCOA.currency,
          this.currentCOA.currency_iso,
          showCurrencyLabel,
          0
        )
      }
      return amount
    },
    calculateBasedOnNewNextPrice () {
      let iCount = 0
      let iTmpSum = 0
      for (const idx in this.next12monthskWhPrice) {
        if (parseInt(this.next12monthskWhPrice[idx]) !== 0) {
          iTmpSum += parseInt(this.next12monthskWhPrice[idx])
          iCount++
        }
      }
      this.total_next_price_average = (iTmpSum / (iCount !== 0 ? iCount : 1)).toFixed(0)
      this.calculateTotalForMonths()
    },
    calculateTotalForMonths () {
      for (let i = 0; i < 12; i++) {
        this.totals[i] = (parseInt(this.next12monthskWhUsed[i]) * parseInt(this.next12monthskWhPrice[i]) / 100).toFixed(0)
        this.totals_diff[i] = 0
        if (parseInt(this.next12monthskWhUsed[i]) !== 0) {
          this.totals_diff[i] = this.totals[i] - parseInt(this.prev12monthsCost[i])
        }
      }
      this.total_next_diff = this.totals_diff.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)
      this.total_next_sum = this.totals.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)
      this.emitAdvancedData()
    },
    async onSaveClick () {
      return new Promise((resolve, reject) => {
        if (!this.isBtnSaveEnabled) {
          resolve(true)
          return true
        }

        this.next12monthskWhPriceCacheForPercentChange = { ...this.next12monthskWhPrice }
        this.next12monthskWhUsedCacheForPercentChange = { ...this.next12monthskWhUsed }

        this.isBtnSaveEnabled = false
        const putData = {
          options: {
            electricity_bill_simulator: JSON.stringify({
              totals: this.totals,
              totals_diff: this.totals_diff,
              prev12monthskWhUsed: this.prev12monthskWhUsed,
              prev12monthsCost: this.prev12monthsCost,
              next12monthskWhUsed: this.next12monthskWhUsed,
              next12monthskWhPrice: this.next12monthskWhPrice,
              totalRow: {
                total_prev_kwh_used: this.total_prev_kwh_used,
                total_prev_price: this.total_prev_price,
                total_next_kwh_used: this.total_prev_kwh_used,
                total_next_price_average: this.total_next_price_average,
                total_next_sum: this.total_next_sum,
                total_next_diff: this.total_next_diff
              }
            })
          }
        }

        axios.put(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/options`, putData)
          .then(response => {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_success').toString(), {
              title: this.$t(this.translationPath + 'title').toString(),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
            resolve(true)
          })
          .catch(err => {
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.save_fail').toString(), {
              title: this.$t(this.translationPath + 'title').toString(),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            })
            reject(err)
          })
          .finally(() => {
            this.isBtnSaveEnabled = true
          })
      })
    },
    async loadSavedData (bgLoad = false) {
      return new Promise((resolve, reject) => {
        if (!bgLoad) {
          this.isLoaded = false
        }
        axios.get(`${process.env.VUE_APP_ROOT_API}/charts-of-accounts/options/electricity_bill_simulator`)
          .then(response => {
            if (response.data.data.electricity_bill_simulator) {
              const data = JSON.parse(response.data.data.electricity_bill_simulator)
              for (const prop in data) {
                this[prop] = data[prop]
              }
              this.next12monthskWhPriceCacheForPercentChange = { ...data.next12monthskWhPrice }
              this.next12monthskWhUsedCacheForPercentChange = { ...data.next12monthskWhUsed }
            }
            resolve(true)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
          .finally(() => {
            this.isLoaded = true
          })
      })
    }
  },
  created () {
    this.loadSavedData()
  },
  watch: {
    prev12monthskWhUsed: {
      deep: true,
      handler () {
        this.total_prev_kwh_used = this.prev12monthskWhUsed.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)
        this.calculateTotalForMonths()
      }
    },
    prev12monthsCost: {
      deep: true,
      handler () {
        this.total_prev_price = this.prev12monthsCost.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)
        this.calculateTotalForMonths()
      }
    },
    next12monthskWhUsed: {
      deep: true,
      handler () {
        this.total_next_kwh_used = this.next12monthskWhUsed.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)
        this.calculateTotalForMonths()
      }
    },
    kwh_used_modification_percentage: {
      handler () {
        for (let i = 0; i < 12; i++) {
          this.next12monthskWhUsed[i] = (this.next12monthskWhUsedCacheForPercentChange[i] * (1 + this.kwh_used_modification_percentage / 100)).toFixed(0)
        }
        this.total_next_kwh_used = this.next12monthskWhUsed.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)
        this.calculateTotalForMonths()
      }
    },
    kwh_price_modification_percent: {
      handler () {
        for (let i = 0; i < 12; i++) {
          this.next12monthskWhPrice[i] = (this.next12monthskWhPriceCacheForPercentChange[i] * (1 + this.kwh_price_modification_percent / 100)).toFixed(0)
        }
        this.calculateBasedOnNewNextPrice()
        this.calculateTotalForMonths()
      }
    },
    kwh_price_modification_price: {
      handler () {
        for (let i = 0; i < 12; i++) {
          this.next12monthskWhPrice[i] = (parseInt(this.next12monthskWhPriceCacheForPercentChange[i]) + parseInt(this.kwh_price_modification_price))
        }
        this.calculateBasedOnNewNextPrice()
        this.calculateTotalForMonths()
      }
    }
  }
}
</script>

<style lang="scss">
.component-simulator-electricity-advanced{
  // return ['#FEC600', '#ff7c43', '#f95d6a', '#d45087', '#a05195', '#665191', '#2f4b7c', '#003f5c', '#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']

  .color01{color: #FEC600;}
  .color02{color: #f95d6a;}
  .color03{color: #525670;}
  .color04{color: #93caac;}
  .bgColor01{ background: #FEC600;}
  .bgColor02{ background: #f95d6a;color:white!important;}
  .bgColor03{ background: #525670;color:white!important;}
  .bgColor04{ background: #93caac;}

  .legend-ul, .legend-ul2{ list-style: none;padding-left: 0;margin-bottom:0;}
  .legend-ul2{padding-bottom: 10px;margin-top:0;padding-top: 0;}
  .legend-ul2 li{font-size: 85%;color: rgba(0, 0, 0, 0.85);}

  .legend-ul li{display: inline;padding-right:10px; }
}
</style>
