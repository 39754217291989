<template>
  <div class="component-reports-simulators-electricity-simulator">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">

            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <electricity-simulator-submenu :active="activeTab" @tab-switched="onTabSwitched"/>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper" :class="{'d-none': activeTab === 'filter'}">

        <!-- Periods / Filter tab has different height -->
        <div class="pb-5" style="width:100%;height:500px;">

          <electricity-basic-chart v-show="activeTab=='simple'"
            :amount="aBasicData.amount"
            :kwh_price="aBasicData.kwh_price"
            :kwh_used="aBasicData.kwh_used"
          />

          <electricity-advanced-chart v-show="activeTab=='advanced'"
            ref="AdvancedChart"
            :prev_prices="aAdvancedData.prev_prices"
            :new_prices="aAdvancedData.new_prices"
            :index="index"
          />

        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->

      <electricity-simulator-basic v-show="activeTab=='simple'"
        @basic-simulator-data="onBasicSimulatorChange"
      />

      <electricity-simulator-advanced v-show="activeTab=='advanced'"
         @advanced-simulator-data="onAdvancedSimulatorChange"
      />

    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import titleMixins from '@/mixins/title'
import ElectricitySimulatorBasic from './basic/ElectricitySimulatorBasic'
import ElectricityBasicChart from './basic/ElectricityBasicChart'
import ElectricitySimulatorAdvanced from './advanced/ElectricitySimulatorAdvanced'
import ElectricitySimulatorSubmenu from './inc/ElectricitySimulatorSubmenu'
import ElectricityAdvancedChart from './advanced/ElectricityAdvancedChart'

export default {
  name: 'ElectricitySimulator',
  mixins: [titleMixins],
  components: {
    ElectricitySimulatorBasic,
    ElectricityBasicChart,
    ElectricitySimulatorSubmenu,
    ElectricitySimulatorAdvanced,
    ElectricityAdvancedChart
  },
  data () {
    return {
      validRouteParams: ['simple', 'advanced'],
      activeTab: 'simple',
      apiData: null,
      translationPath: 'reports.simulators.electricity.',
      busyState: true,
      chartToDisplay: 'bar-chart',
      aBasicData: {
        amount: 7500,
        kwh_used: 7500,
        kwh_price: 100
      },
      aAdvancedData: {
        prev_prices: new Array(12).fill(0),
        new_prices: new Array(12).fill(0)
      },
      index: 1
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      let aBreadcrumb = []

      aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports/performance' },
        { text: this.$t('reports._common.simulator_reports'), to: '/reports/simulators' },
        { text: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    /**
     * Handle emitted data from period_selector
     */
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) !== -1) {
        this.$router.push({ params: { tab: newtab } })
      } else if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) === -1) {
        this.$router.push({ params: { tab: 'summary' } })
      }
    },
    onBasicSimulatorChange (data) {
      this.aBasicData.amount = parseInt(data.amount)
      this.aBasicData.kwh_used = parseInt(data.kwh_used)
      this.aBasicData.kwh_price = parseInt(data.kwh_price)
    },
    onAdvancedSimulatorChange (data) {
      this.aAdvancedData.prev_prices = data.prev_prices
      this.aAdvancedData.new_prices = data.new_prices
      this.index++
      console.log('Received')
      console.log(this.aAdvancedData.new_prices)
    }
  },
  mounted () {
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    } else {
      this.$router.push({ params: { tab: 'summary' } })
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' (newVal) {
      if (this.activeTab === 'advanced') {
        this.$refs.AdvancedChart.$renderSpike()
      }
    }
  }
}
</script>

<style lang="scss">
.component-reports-simulators-electricity-simulator{
  width: 100%;
}
</style>
