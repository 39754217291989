<template>
  <div class="component-simulator-electricity-basic">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="8">
                  <h2>{{ $t(translationPath + 'title') }}</h2>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0">
              <div v-html="$t(translationPath + 'description_basic_simulator')"></div>
            </div>

            <b-row no-gutters>
              <b-col class="col-lg-8">
                <b-form-group
                  label-class="FormLabels"
                  label-for="kwh_used"
                  label-cols="12"
                  label-align="left"
                  :label="$t(translationPath + 'label.kwh_used')"
                >
                  <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                    <custom-number-input
                      id="kwh_used"
                      v-model.number="kwh_used"
                      :precision="0"
                      :min="0"
                      :max="8000"
                      :suffix="' kWh'"
                      :state="$v.kwh_used.$dirty ? !$v.kwh_used.$error : null"
                      @input="$v.kwh_used.$touch()"
                    />
                  </b-col>
                  <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                    <b-form-input
                      id="kwh_used_slider"
                      class="slider ml-2"
                      type="range"
                      :min="0"
                      :max="8000"
                      :step="100"
                      v-model.number="kwh_used"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col class="col-lg-8">
                <b-form-group
                  label-class="FormLabels"
                  label-for="amount"
                  label-cols="12"
                  label-align="left"
                  :label="$t(translationPath + 'label.amount')"
                >
                  <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                    <currency-input
                      ref="CurrencyInputAmount"
                      id="amount"
                      v-model.number="amount"
                      :precision="0"
                      :min="0"
                      :max="50000"
                      :input-class="$v.amount.$error ? 'is-invalid' : ''"
                      :state="$v.amount.$dirty ? !$v.amount.$error : null"
                      @input="$v.amount.$touch()"
                    />
                  </b-col>
                  <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                    <b-form-input
                      id="amount_slider"
                      class="slider ml-2"
                      type="range"
                      :min="0"
                      :max="50000"
                      :step="100"
                      v-model.number="amount"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col class="col-lg-8">
                <b-form-group
                  label-class="FormLabels"
                  label-for="kwh_price"
                  label-cols="12"
                  label-align="left"
                  :label="$t(translationPath + 'label.kwh_price')"
                >
                  <b-col sm="12" md="5" xl="3" class="float-left mb-2 pl-0 pr-0">
                    <custom-number-input
                      id="kwh_price"
                      v-model.number="kwh_price"
                      :precision="0"
                      :min="0"
                      :max="2000"
                      :suffix="' öre/kWh'"
                      :input-class="$v.kwh_price.$error ? 'is-invalid' : ''"
                      :state="$v.kwh_price.$dirty ? !$v.kwh_price.$error : null"
                      @input="$v.kwh_price.$touch()"
                    />
                  </b-col>
                  <b-col sm="12" md="7" xl="9" class="float-left pl-0 pb-2">
                    <b-form-input
                      id="kwh_price_slider"
                      class="slider ml-2"
                      type="range"
                      :min="0"
                      :max="2000"
                      :step="5"
                      v-model.number="kwh_price"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required, integer, minValue } from 'vuelidate/lib/validators'
import CurrencyInput from '@/components/common/CurrencyInput'
import useComponentCurrencyInputMixin from '@/mixins/useComponentCurrencyInput'
import CustomNumberInput from '@/components/common/CustomNumberInput'
import _ from 'lodash'

export default {
  name: 'ElectricitySimulatorBasic',
  mixins: [useComponentCurrencyInputMixin],
  components: { CurrencyInput, CustomNumberInput },
  props: {
  },
  data () {
    return {
      translationPath: 'reports.simulators.electricity.submenu.basic.',
      amount: 7500,
      kwh_used: 7500,
      kwh_price: 100,
      paramsPrev: {
        amount: 7500,
        kwh_used: 3000,
        kwh_price: 250
      }
    }
  },
  computed: {
    ...mapState('user', ['currentCOA'])
  },
  created () {
    this.debounceCalcAmount = _.debounce(this.calculateAmount, 600)
    this.debounceCalcKwhPrice = _.debounce(this.calculatekWhPrice, 600)
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    emitBasicData () {
      this.$emit('basic-simulator-data', { amount: this.amount, kwh_used: this.kwh_used, kwh_price: this.kwh_price })
    },
    calculateAmount () {
      this.amount = parseInt(Math.round(this.kwh_used * this.kwh_price / 100).toFixed(0))
      this.emitBasicData()
    },
    calculatekWhPrice () {
      if (this.kwh_used !== 0) {
        this.kwh_price = parseInt(Math.round(this.amount / this.kwh_used * 100).toFixed(0))
        this.emitBasicData()
      }
    }
  },
  validations: {
    amount: {
      required,
      integer,
      minValue: minValue(0)
    },
    kwh_used: {
      required,
      integer,
      minValue: minValue(0)
    },
    kwh_price: {
      required,
      integer,
      minValue: minValue(0)
    }
  },
  watch: {
    amount: {
      handler () {
        this.rerenderCurrencyInputs()
        this.debounceCalcKwhPrice()
      }
    },
    kwh_used: {
      handler () {
        this.debounceCalcKwhPrice()
      }
    },
    kwh_price: {
      handler () {
        this.debounceCalcAmount()
      }
    }
  }
}
</script>

<style lang="scss">
.component-cashflow-quadrant-table-total{
}
</style>
