<template>
  <b-container fluid class="component-simulator-electricity-basic-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'simulator-electricity-basic-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
// import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'ElectricityBasicChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    amount: { type: Number, default: 0 },
    kwh_used: { type: Number, default: 7500 },
    kwh_price: { type: Number, default: 100 }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.simulators.electricity.chart'
    }
  },
  created () {
    this.fillChart()
  },
  methods: {
    fillChart () {
      const labels = [
        'vid ' + this.kwh_price + ' öre/kWh | ' + this.amount + ' kr',
        'Vid ' + (this.kwh_price * 2) + ' öre/kWh | ' + this.amount * 2 + ' kr (diff: +' + this.amount + ' kr)',
        'Vid ' + (this.kwh_price * 3) + ' öre/kWh | ' + this.amount * 3 + ' kr (diff: +' + this.amount * 2 + ' kr)'
      ]

      const sBackgroundColor = this.getYellow() // this.account.type === 'income' ? this.getGreen() : this.getRed()
      const dataset = { backgroundColor: sBackgroundColor, type: 'bar', data: [] }
      this.chartOptions.legend.display = false

      dataset.data.push(this.amount)
      dataset.data.push(this.amount * 2)
      dataset.data.push(this.amount * 3)

      this.chartData = {
        labels: labels,
        datasets: [dataset]
      }
    }
  },
  watch: {
    amount: {
      handler: 'fillChart',
      immediate: true,
      deep: true
    }
  }
}
</script>
