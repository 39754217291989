<template>
  <b-container fluid class="component-simulator-electricity-advanced-chart">
    <bar-chart
      :key="key"
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'simulator-electricity-advanced-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import defaultChartPeriodMixin from '@/mixins/defaultChartPeriodMixin'
// import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'ElectricityAdvancedChart',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, defaultChartPeriodMixin],
  props: {
    prev_prices: { type: Array, default: new Array(12).fill(0) },
    new_prices: { type: Array, default: new Array(12).fill(0) },
    index: { type: Number, default: 0 }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.simulators.electricity.chart.',
      key: 0
    }
  },
  mounted () {
    this.fillChart()
  },
  methods: {
    fillChart () {
      const labels = []
      const iTotalPrevSum = this.prev_prices.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)
      const iTotalNextSum = this.new_prices.reduce((partialSum, a) => parseInt(partialSum) + parseInt(a), 0)

      const dataset = { backgroundColor: this.getYellow(), type: 'bar', data: [], label: this.$t(this.translationPath + 'prev_period', { total: iTotalPrevSum }) }
      const dataset2 = { backgroundColor: this.getGreen(), type: 'bar', data: [], label: this.$t(this.translationPath + 'new_period', { total: iTotalNextSum }) }

      this.chartOptions.legend.display = true

      for (let i = 1; i <= 12; i++) {
        labels.push(this.$t('common.months.month' + i + '_short'))
        dataset.data.push(parseInt(this.prev_prices[(i - 1)]))
        dataset2.data.push(this.new_prices[(i - 1)])
      }

      this.chartData = {
        labels: labels,
        datasets: [dataset, dataset2]
      }
      // console.log(this.chartData)
    },
    $renderSpike () {
      if (!this.key) {
        this.key++
      }
    }
  },
  watch: {
    index: {
      handler: 'fillChart'
    }
  }
}
</script>
